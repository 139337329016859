import React from "react";
import mainimg from "../images/main.png";

const Content = () => {
  const goToGoogleStore = () => {
    window.location.href = "https://chrome.google.com/webstore/detail/piezo-run/cnllhpdmgehecabkpehokonfnladpddn";
  };

  return (
    <div className=" grid grid-cols-2 max-w-6xl h-96  m-auto pl-8 pr-8 gap-x-20">
      <div className=" col-span-1 flex items-center">
        <div className="text-left">
          <h1 className="text-5xl font-bold block w-full col-span-full">
            Piezo
          </h1>
          <p className="block col-span-full py-8 tracking-wide">
            Discover new shows and movies right from your browser.Keep yourself
            updated with the latest news and movies.
          </p>
          <p>Powered by Bing</p>
          <button
            class=" uppercase tracking-wide border-2 text-xl hover:bg-blue-50 hover:bg-opacity-20 font-semibold text-white  py-3 px-8  transition duration-300 ease-out"
            onClick={goToGoogleStore}
          >
            Get Extension
          </button>
        </div>
      </div>
      <div className=" col-span-1 flex justify-center text-left items-center">
        <img className="shadow-xl" src={mainimg} width={600} alt="hero" />
      </div>
    </div>
  );
};

export default Content;
