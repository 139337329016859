import React from "react";

const Footer = () => {
  return (
    <footer className="w-full bg-gray-800 text-white pt-12 pb-12 tracking-wide">
      <div className="max-w-7xl pl-5 pr-5  m-auto grid grid-cols-3">
        <div className="col-span-1 ">
          <h3 className="uppercase text-2xl font-medium">Copyright ©Jukebucks 2022</h3>
          <p className="pt-2">All Rights Reserved, powered by Bing</p>
        </div>
        <div className="col-span-1  flex flex-wrap">
          <div className="w-full">
            <h3 className="uppercase text-2xl font-medium w-full">
              made for your browser
            </h3>
          </div>
          <div className="w-full">
            <ul className="flex w-3/4 m-auto mt-4 justify-center">
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-span-1 ">
          <h3 className="uppercase text-2xl font-medium">Contact</h3>
          <p className="pt-2 pl-2 pr-2">
            Email: <a href="mailto:peter@jukebucks.org" target="_blank">peter@jukebucks.org</a>
            Address: 2081 Center St, Berkeley, CA 94704, United States
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
