import React from "react";
import Hero from "../components/Hero";
// import IconList from "./components/IconList";
import Brands from "../components/Brands";
import Join from "../components/Join";
import Footer from "../components/Footer";
import Features from "../components/Features";

const Home = () => {
  return (
    <>
      <Hero />
      <Brands />
      <Features />
      {/* <IconList /> */}
      <Join />
      <Footer />
    </>
  );
};

export default Home;
