import React from "react";

const LeftPhoto = ({image, title, description}) => {
  return (
    <section className="w-full bg-gray-100 pt-24 pb-24">
      <div className="max-w-7xl m-auto pl-6 pr-6 grid grid-cols-2">
        <div className="col-span-1 flex justify-center items-center">
          <img
            className="shadow-lg"
            src={image}
            width="500px"
            alt="left"
          />
        </div>
        <div className="col-span-1 text-left pl-10 pr-10">
          <h2 className=" text-2xl">{title}</h2>
          <p className="text-gray-700 pt-4 pb-4 tracking-wide">
            {description}
          </p>
        </div>
      </div>
    </section>
  );
};

export default LeftPhoto;
