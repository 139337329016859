import React from "react";

const Join = () => {
  return (
    <section className="bg-no-repeat bg-center bg-cover pt-36 pb-36 bg-yellow-300 text-black">
      <h1 className="text-4xl  font-medium">
        Join other users and find your next favorite shows
      </h1>
      {/* <button class=" uppercase tracking-wide mt-16 border-2 border-black hover:bg-blue-50 hover:bg-opacity-20 font-semibold  text-xl py-3 px-10  transition duration-300 ease-out">
        Get extension
      </button> */}
    </section>
  );
};

export default Join;
