import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-5xl font-bold text-yellow-500">404</h1>
        <h2 className="text-3xl font-bold text-yellow-500">Page not found</h2>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
