import React from "react";
import Navbar from "./Navbar";
import Content from "./Content";

const Hero = () => {
  return (
    <div className="  text-white h-screen  bg-gray-800 z-10 bg-no-repeat bg-center bg-cover font-Montserrat relative">
      <Navbar />
      <div className=" pt-28 ">
        <Content />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="absolute bottom-0 -z-5 ">
        <path
          fill="#ffd700"
          fill-opacity="1"
          d="M0,256L1440,32L1440,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default Hero;
