import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <section className="terms-section max-w-7xl px-1 m-auto py-10 text-left flow-content">
        <h2 className=" font-bold text-2xl">Privacy Policy</h2>
        <p>Last Updated : 2022-01-15</p>
        <h3 className="font-bold text-xl">About Us</h3>
        <p>
          This extension is owned and operated by the Jukebucks team. We
          respect the privacy of users of our extension products and have
          developed this privacy policy (the "Privacy Policy") to demonstrate
          our commitment to protecting your privacy.
        </p>
        <h3 className="font-bold text-xl">Scope</h3>
        <p>
          This Privacy Policy applies to information collected through{" "}
          Jukebucks (“we”, “us” or “our”), whether accessed via computer,
          mobile device, or other technology (collectively, the “Services”).
        </p>
        <p>
          This Privacy Policy explains what information we may collect through
          the Services, how such information may be used and/or shared with
          others, how we safeguard it, and your choices about such uses and
          disclosures.
        </p>
        <p>
          Please read this Privacy Policy carefully, prior to using the
          Services. By using the Services, you are acknowledging that you
          understand and agree to the terms hereof as well as the terms &
          conditions set forth on the Site.
        </p>
        <h3 className="font-bold text-xl">What Information We Collect</h3>
        <p>
          Information collected directly from your usage and interaction with
          our Services is as follows:
        </p>
        <div className="flex gap-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold">Category</p>
            <p>Online Identifiers</p>
            <p>Usage Information</p>
            <p>Geolocation</p>
            <p>Others</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">Examples</p>
            <p>User-Agent, IP address, User ID, Operating System</p>
            <p>Page served, time, referring URLs</p>
            <p>Geographic location associated with your IP address</p>
            <p>Search queries</p>
          </div>
        </div>
        <h3 className="font-bold text-xl">How we Collect your Information</h3>
        <p>
          The data detailed in the table above is collected Automatically,
          mainly through the use of cookies.
        </p>
        <h3 className="font-bold text-xl">Cookies</h3>
        <p>
          Cookies are small text files placed on a Device when the Device is
          used to visit the Service or the Site. The extension collects your
          information with cookies on the search domain level.
        </p>
        <h3 className="font-bold text-xl">Managing Cookies/Opt-Out</h3>
        <p>
          We do not utilize cookies other than necessary, strictly functional
          cookies for the sole purpose of either operating the website of the
          extension or operating and enabling the functionality of the
          extensions you installed.
        </p>
        <p>
          For example, cookies of our hosting service for functional,
          operational purposes.
        </p>
        <p>
          While we cannot provide you or encourage you to opt-out of necessary,
          strictly functional cookies as they are necessary for us to be able to
          provide our service, you can disable them (though not recommended as
          it may impact the usability of our service) by following your
          browser’s instructions.
        </p>
        <p>
          Most browsers allow you to refuse to accept cookies and delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via this{" "}
          <a
            href="https://support.google.com/chrome/answer/95647?hl=en"
            className="cursor-pointer underline"
          >
            link
          </a>
          .
        </p>
        <p>
          In addition, we may offer Oath search feed as part of our Extension
          and thus it may include cookies on its behalf - more information is
          available here. In addition, opt-out information which will enable you
          to limit the data collection by OATH is available here.
        </p>
        <h3 className="font-bold text-xl">How We Use Your Information</h3>
        <p>We use information that we collect about you for</p>
        <ol className="pl-4 flow-content list-disc">
          <li>Operating the Extension</li>
          <li>Providing customized Service</li>
          <li>
            The internal operation of debugging, support, fraud detection, and
            security
          </li>
          <li>Monetization with third-party search partners</li>
        </ol>
        <h3 className="font-bold text-xl">How We Share Your Information</h3>
        <p>
          We do not disclose information collected from you with any third
          party, other than with the service providers we use solely for the
          purposes detailed in the above section. Below is a detailed list of
          our third-party service providers that we share information with:
        </p>
        <p>Cloudflare (name server, DNS, caching)</p>
        <p>Yahoo search feed (third party search monetization)</p>
        <p>
          We also reserve the right to disclose your information with third
          parties if we are legally required to do so, to cooperate with law
          enforcement investigations or other legal proceedings, or to protect
          against misuse or unauthorized use of the Service, solely to the
          extent needed to enforce our policies and agreements and to
          investigate potential violations thereof.
        </p>
        <h3 className="font-bold text-xl">How We Protect Your Information</h3>
        <p>
          We maintain tight controls over all the data we collect, retaining it
          in secured databases with limited and controlled access rights. Please
          remember that unfortunately, the transmission of information via the
          internet is not completely secure, so although we will do our best to
          protect your Personal Data, we cannot guarantee the security of your
          data transmitted via the Service; any transmission is at your own
          risk.
        </p>
        <h3 className="font-bold text-xl">How Long We Keep Your Information</h3>
        <p>
          We only keep your personal information as long as it is necessary for
          the purposes set out in this Privacy Policy including for legitimate
          business purposes and as permitted by applicable law. We will only use
          your information to the extent necessary to comply with our legal
          obligations, resolve any legal issues, enforce our agreements, and as
          otherwise described in this policy.
        </p>
        <p>
          In order to protect our users, we opted to use one-way encryption of
          IP addresses in a way that does not allow for it to be associated with
          any person nor linked to the other information we may have, which we
          store in a non-identifiable way.
        </p>
        <p>
          Please note, that once the Extension has been removed from your
          browser by you, we can no longer trace any user activity nor can we
          link you with any unidentifiable information we may have in our
          database.
        </p>
        <h3 className="font-bold text-xl">
          Notice to EU Residents under the ”GDPR”
        </h3>
        <p>
          This section applies to you if you are a national of a European
          Economic Area (“EEA”) member state. This section describes our good
          faith effort to meet our obligations under the GDPR.
        </p>
        <h3 className="font-bold text-xl">Personal Data</h3>
        <p>
          The GDPR defines “personal data” as “any information relating to an
          identified or identifiable natural person (‘data subject’); an
          identifiable natural person is one who can be identified, directly or
          indirectly, in particular by reference to an identifier such as a
          name, an identification number, location data, an online identifier or
          to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural or social identity of that natural
          person”
        </p>
        <p>
          For the purposes of our Privacy Policy, the “data subject” is “you”
          (“your”, “user”). The personal data we collect may include: the unique
          User ID we assign you.
        </p>
        <p>
          The personal data we collect will only be used and disclosed for the
          purposes detailed in the above sections of this Privacy Policy.
        </p>
        <p>
          Our basis for processing personal data: GDPR Article 6.1(a): “the data
          subject has given consent to the processing of his or her personal
          data for one or more specific purposes”; GDPR Article 6.1(b):
          “processing is necessary for the performance of a contract to which
          the data subject is party or in order to take steps at the request of
          the data subject prior to entering into a contract”; GDPR Article
          6(1)(f): “processing is necessary for the purposes of the legitimate
          interests pursued by the controller or by a third party…”
        </p>
        <h3 className="font-bold text-xl">Your Rights</h3>
        <p>Your rights as a GDPR data subject include the following:</p>
        <ol className="pl-4 flow-content list-decimal">
          <li> Request a copy of the information, if any, stored about you.</li>
          <li>Correct information, if any, stored about you.</li>
          <li> Erase information, if any, stored about you.</li>
          <li>Restrict how information, if any, about you is used.</li>
          <li>Transfer the information, if any, about you.</li>
          <li>
            Object to how your information, if any, is used in certain cases.
          </li>
          <li>
            Request why we are unable to fulfill your request, if applicable.
          </li>
        </ol>
        <p>
          You can contact us to submit any GDPR related inquiry or request by
          submitting an inquiry to peter@jukebucks.org.
        </p>
        <p>
          Please note that we can only meet sufficiently verifiable user
          requests. Sufficiently verifiable user requests refer to requests that
          allow us to reasonably match the information provided by the user
          making the request with the information already available to us,
          insofar non-pseudonymous Personal Data is available.
        </p>
        <p>
          Please note that we can only meet sufficiently verifiable user
          requests. Sufficiently verifiable user requests refer to requests that
          allow us to reasonably match the information provided by the user
          making the request with the information already available to us,
          insofar non-pseudonymous Personal Data is available.
        </p>
        <p>
          In view of the above, and due to opting to use one-way encryption for
          IPs, in a pseudonymous manner, we may not have any personal
          information of you on record we can provide you with.
        </p>
        <h3 className="font-bold text-xl">
          Notice to California Residents under the ”CCPA”
        </h3>
        <p>
          This Section applies to you if you are a natural person who is a
          California resident, as defined in the California Code of Regulations.
          This section describes our good faith effort to meet our obligations
          under the CCPA.
        </p>
        <h3>Personal Information</h3>
        <p>
          The CCPA defines “personal information” as including “…information
          that identifies, relates to, describes, is capable of being associated
          with, or could reasonably be linked, directly or indirectly, with a
          particular consumer or household.”
        </p>
        <p>
          For the purposes of our Privacy Policy, the “person” is “you” (“your”,
          “user”). The personal information we may collect includes your IP
          Address; the unique User ID we assign you; the User-Agent and version
          of your web browser; and the Operating System and version used by your
          hardware.
        </p>
        <p>
          The personal information we collect will only be used and disclosed
          for the business purposes detailed in sections 5 and 6 of this Privacy
          Policy, which is necessary for us in order to provide you with the
          Service.
        </p>
        <h5 className="font-bold text-xl">
          Your Rights / Opt-Out for “Sale of Personal Information”
        </h5>
        <p>
          You can contact us to submit any CCPA related inquiry or request via
          the following 2 methods:
        </p>
        <ul className="pl-4 list-disc flow-content">
          <li>Submitting an inquiry to peter@jukebucks.org</li>
          <li>Sending a physical inquiry to 2081 Center St, Berkeley, CA 94704, United States</li>
        </ul>
        <p>
          We will not discriminate against you for exercising any of your CCPA
          rights.
        </p>
        <p>
          Please be advised that we do not explicitly sell your personal
          information (nor do we use non-pseudonymous Personal Data) therefore,
          there is no need for you to opt out of the potential sale of your
          personal information.
        </p>
        <p>
          <span className="underline">
            Please note that we can only meet sufficiently verifiable user
            requests.{" "}
          </span>
          Sufficiently verifiable user requests refer to requests that allow us
          to reasonably match the information provided by the user making the
          request with the information already available to us, insofar
          non-pseudonymous Personal Data is available.
        </p>
        <p>
          In view of the above, and due to opting to use one-way encryption for
          IPs, in a pseudonymous manner, we may not have any personal
          information of you on record we can provide you with.
        </p>
        <h5 className="font-bold text-xl">Children</h5>
        <p>
          The Service is not directed to children, as defined under applicable
          laws and specifically under 16 in the EEA or 13 in the U.S.A
          (“Child”). We do not knowingly collect Personal Information from
          anyone that is considered to be a child. If we determine upon
          collection that a user is a child, we will not use or maintain his/her
          Personal Information without the parent/guardian’s consent. If we
          become aware that we have unknowingly collected Personal Information
          from a child under the age of 13, we will make reasonable efforts to
          delete such information from our records. We encourage parents and
          legal guardians to be familiar with the Internet activity of their
          children.
        </p>
        <h5 className="font-bold text-xl">How to remove the extension</h5>
        <p>
          As a user of our extension, you can remove the extension from your
          browser at any time. For simple steps to remove our extension, please
          click uninstall.
        </p>
        <h5 className="font-bold text-xl">Updates to Privacy Policy</h5>
        <p>
          Please note, that we may modify the information presented via the
          extension and/or this Privacy Policy from time to time. You should
          check back here periodically to see if the Privacy Policy has been
          updated. We will always show the date of the latest modification of
          the Privacy Policy at the top of the page so you can tell when it was
          last revised.
        </p>
        <h5>Contact us</h5>
        <p>
          We regularly review our compliance with this Privacy Policy.
          Questions, comments, and requests regarding this Privacy Policy are
          welcomed and should be addressed to peter@jukebucks.org.
        </p>
        <p></p>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
