import React from "react";
import logo from "../images/logo.png";

const Navbar = () => {
  return (
    <div className="w-full bg-gray-900 bg-opacity-20">
      <div className="flex justify-between  m-auto px-6 py-2 max-w-6xl font-bold">
        <div className="flex items-center">
          <img src={logo} alt="piezologo" width={50} />
          <span className="pl-2 font-Montserrat font-semibold text-xl">
            Piezo
          </span>
        </div>
        <nav className="flex items-center font-medium">
          <ul className="flex  uppercase">
            <li className="pl-4 pr-4">
              <a
                href="/"
                className="hover:text-yellow-200 transition ease-out duration-300"
              >
                Home
              </a>
            </li>
            <li className="pl-4 pr-4">
              <a
                href="/terms"
                className="hover:text-yellow-200 transition ease-out duration-300"
              >
                Terms
              </a>
            </li>
            <li className="pl-4 pr-4">
              <a
                href="/privacy"
                className="hover:text-yellow-200 transition ease-out duration-300"
              >
                Privacy
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
