import React from "react";
import { Link } from "react-scroll";

const Brands = () => {
  return (
    <section className="w-full pt-12 pb-20">
      <div className="max-w-5xl m-auto ">
        <h1 className="pt-9 pb-9 text-4xl font-semibold">Features of Piezo</h1>
        <div className="w-full mt-10 grid grid-cols-3 gap-x-40">
          <Link
            className="group w-full bg-white cursor-pointer"
            to="movie"
            smooth={true}
            duration={1000}
          >
            <div className="brand_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div className="block pt-4 pb-4 ">
              <h3 className="text-black text-xl group-hover:text-yellow-500">
                Movie Details
              </h3>
            </div>
          </Link>

          <Link
            className="group w-full bg-white cursor-pointer"
            to="tvshow"
            smooth={true}
            duration={1000}
          >
            <div className="brand_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"
                />
              </svg>
            </div>
            <div className="block pt-4 pb-4 ">
              <h3 className="text-black text-xl group-hover:text-yellow-500">
                TV show
              </h3>
            </div>
          </Link>

          <Link
            className="group w-full bg-white cursor-pointer"
            to="search"
            smooth={true}
            duration={1300}
          >
            <div className="brand_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <div className="block pt-4 pb-4 ">
              <h3 className="text-black text-xl group-hover:text-yellow-500">
                Search results
              </h3>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Brands;
